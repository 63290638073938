import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  List,
  maxLength,
  minLength,
  BooleanInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanField,
} from 'react-admin';
import { ResourcePagination } from './utils';

import ReferenceFileDirectInput from './components/ReferenceFileDirectInput';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 23;
const THUMBNAIL_MAX_FILE_SIZE_BYTES = 1048576;
const PREVIEW_MAX_FILE_SIZE_BYTES = 10485760;
const ARCHIVE_MAX_FILE_SIZE_BYTES = 314572800;

const requiredReferenceId = () => (value) => {
  if (value && !value.id) {
    return 'File is not yet uploaded';
  }
  return undefined;
};

const maxReferenceFileSize = (maxSizeInBytes) => (value) => {
  if (typeof value !== 'object' || !value.meta) {
    // this means no new file was uploaded
    return undefined;
  }
  if (!value.meta.size) {
    return 'Uploaded file size unknown or 0';
  }
  if (value.meta.size > maxSizeInBytes) {
    return `Uploaded file size must not be bigger than ${convertBytes(maxSizeInBytes)}`;
  }
  return undefined;
};

const convertBytes = function(bytes) {
  const sizes = ['Bytes', 'KB', 'MB'];
  const magnitude = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (magnitude === 0) {
    return bytes + ' ' + sizes[magnitude];
  }
  return (bytes / Math.pow(1024, magnitude)).toFixed(1) + ' ' + sizes[magnitude];
};

export const BackgroundCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <SelectInput
          label="Background type"
          source="type"
          choices={[
            { id: 'plain', name: 'Plain'},
            { id: 'pattern', name: 'Pattern'},
            { id: 'augmented', name: 'AR augmented'},
          ]}
          validate={[required()]}
        />
        <ReferenceInput label="Background category" source="backgroundCategory.id" reference="BackgroundCategory">
          <SelectInput optionText="name" resettable />
        </ReferenceInput>
        <ReferenceFileDirectInput
          source="backgroundFile"
          label="Background Archive"
          referenceSource="backgroundFileId"
          reference="File"
          placeholder="Select background Archive"
          accept="video/quicktime,video/mp4,video/mpeg,application/mp4,application/zip,application/x-7z-compressed,image/jpeg,image/jpg,image/png,image/gif"
          folder="models"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(ARCHIVE_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="preview"
          label="Preview Image"
          referenceSource="previewId"
          reference="File"
          placeholder="Select preview image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <BooleanInput label="Is Colorable?" source="isColorable" />
        <BooleanInput label="Is Active?" source="isActive" />
        <BooleanInput label="Is Featured?" source="isFeatured" />
      </SimpleForm>
    </Create>
  );
};

export const BackgroundEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <SelectInput label="Background type" source="type" choices={[
          { id: 'plain', name: 'Plain'},
          { id: 'pattern', name: 'Pattern'},
          { id: 'augmented', name: 'AR augmented'},
        ]}/>
        <ReferenceInput label="Background category" source="backgroundCategory.id" reference="BackgroundCategory">
          <SelectInput optionText="name" resettable />
        </ReferenceInput>
        <ReferenceFileDirectInput
          source="backgroundFile"
          label="Background Archive"
          referenceSource="backgroundFileId"
          reference="File"
          placeholder="Select background Archive"
          accept="video/quicktime,video/mp4,video/mpeg,application/mp4,application/zip,application/x-7z-compressed,image/jpeg,image/jpg,image/png"
          folder="models"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(ARCHIVE_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="preview"
          label="Preview Image"
          referenceSource="previewId"
          reference="File"
          placeholder="Select preview image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <BooleanInput label="Is Colorable?" source="isColorable" />
        <BooleanInput label="Is Active?" source="isActive" />
        <BooleanInput label="Is Featured?" source="isFeatured" />
      </SimpleForm>
    </Edit>
  );
};

export const BackgroundList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ResourcePagination />}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="type" />
        <BooleanField label="Is Colorable?" source="isColorable" />
        <BooleanField label="Is Active?" source="isActive" />
        <BooleanField label="Is Featured?" source="isFeatured" />
      </Datagrid>
    </List>
  );
};
