import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  List,
  maxLength,
  minLength,
  NumberField,
  NumberInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import ReferenceFileDirectInput from './components/ReferenceFileDirectInput';
import {requiredReferenceId, maxReferenceFileSize, ResourcePagination} from './utils';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 23;
const DESCRIPTION_MAX_LENGTH = 280;
const FONT_MAX_FILE_SIZE_BYTES = 1048576;
const THUMBNAIL_MAX_FILE_SIZE_BYTES = 1048576;

export const CategoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <BooleanInput label="Is Featured?" source="featured" />
        <TextInput
          source="description"
          multiline
          style={{width: 420}}
          validate={[maxLength(DESCRIPTION_MAX_LENGTH)]}
        />
        <NumberInput source="sortNumber" step={1} />
        <ReferenceFileDirectInput
          source="fontFile"
          label="Category font"
          referenceSource="fontFileId"
          reference="File"
          placeholder="Select Category font file"
          accept=".ttf, .otf"
          folder="resources"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(FONT_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select Thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />

        <ReferenceFileDirectInput
          source="phoneBanner"
          label="Phone Banner Image"
          referenceSource="phoneBannerId"
          reference="File"
          placeholder="Select Phone Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletPortraitBanner"
          label="Tablet Portrait Banner Image"
          referenceSource="tabletPortraitBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Portrait Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletLandscapeBanner"
          label="Tablet Landscape Banner Image"
          referenceSource="tabletLandscapeBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Landscape Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />

        <ReferenceFileDirectInput
          source="phoneFeaturedBanner"
          label="Phone Featured Banner Image"
          referenceSource="phoneFeaturedBannerId"
          reference="File"
          placeholder="Select Phone Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletFeaturedPortraitBanner"
          label="Tablet Portrait Featured Banner Image"
          referenceSource="tabletFeaturedPortraitBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Portrait Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletFeaturedLandscapeBanner"
          label="Tablet Landscape Featured Banner Image"
          referenceSource="tabletFeaturedLandscapeBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Landscape Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        
        <ColorInput
          source="fontColor"
        />
        <NumberInput source="fontSize" step={1} />
        <BooleanInput label="Protect from deletion by sync script?" source="protectFromDeletionDuringSync" />
      </SimpleForm>
    </Create>
  );
};

export const CategoryEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <BooleanInput label="Is Featured?" source="featured" />
        <TextInput
          source="description"
          multiline
          style={{width: 420}}
          validate={[maxLength(DESCRIPTION_MAX_LENGTH)]}
        />
        <NumberInput source="sortNumber" step={1} />
        <ReferenceFileDirectInput
          source="fontFile"
          label="Category font"
          referenceSource="fontFileId"
          reference="File"
          placeholder="Select Category font file"
          accept=".ttf, .otf"
          folder="resources"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(FONT_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select Thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />

        <ReferenceFileDirectInput
          source="phoneBanner"
          label="Phone Banner Image"
          referenceSource="phoneBannerId"
          reference="File"
          placeholder="Select Phone Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletPortraitBanner"
          label="Tablet Portrait Banner Image"
          referenceSource="tabletPortraitBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Portrait Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletLandscapeBanner"
          label="Tablet Landscape Banner Image"
          referenceSource="tabletLandscapeBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Landscape Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />

        <ReferenceFileDirectInput
          source="phoneFeaturedBanner"
          label="Phone Featured Banner Image"
          referenceSource="phoneFeaturedBannerId"
          reference="File"
          placeholder="Select Phone Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletFeaturedPortraitBanner"
          label="Tablet Portrait Featured Banner Image"
          referenceSource="tabletFeaturedPortraitBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Portrait Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="tabletFeaturedLandscapeBanner"
          label="Tablet Landscape Featured Banner Image"
          referenceSource="tabletFeaturedLandscapeBannerId"
          reference="File"
          placeholder="Select Tablet/Ipad Landscape Featured Banner image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ColorInput
          source="fontColor"
        />
        <NumberInput source="fontSize" step={1} />
        <BooleanInput label="Protect from deletion by sync script?" source="protectFromDeletionDuringSync" />
      </SimpleForm>
    </Edit>
  );
};

export const CategoryList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ResourcePagination />}
      sort={{field: 'name', order: 'ASC'}}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <BooleanField label="Is Featured?" source="featured" />
        <TextField source="description" />
        <NumberField source="sortNumber" />
      </Datagrid>
    </List>
  );
};
