import React, { useEffect, useState } from 'react';
import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Admin, Resource } from 'react-admin';
import buildGraphQLProvider from 'ra-data-graphql-simple';

import AuthProvider from './auth.provider';
import { CategoryCreate, CategoryEdit, CategoryList } from './categories';
import { BackgroundCategoryCreate, BackgroundCategoryEdit, BackgroundCategoryList } from './backgroundCategories';
import { BackgroundCreate, BackgroundEdit, BackgroundList } from './backgrounds';
import { CharacterCreate, CharacterEdit, CharacterShow, CharacterList } from './characters';
import { FileShow, FileList } from './files';
import { UserVideoCreate, UserVideoEdit, UserVideoList } from './userVideos';
import { UserGeneratedContentEdit, UserGeneratedContentList } from './userGeneratedContent';
import config from './config';
import { videoQueryHandler } from './videoQueryInterceptor';

const httpLink = createHttpLink({
  uri: `${config.API_HOST}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const authPayloadRaw = localStorage.getItem('auth');
  if (!authPayloadRaw) {
    return null;
  }
  const authPayload = JSON.parse(authPayloadRaw);
  const accessToken = await AuthProvider.checkAndRefreshToken(authPayload);
  if (!accessToken) {
    return null;
  }
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

function App() {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const setupDataProvider = async () => {
      const builtDataProvider = await buildGraphQLProvider({
        clientOptions: {
          link: authLink.concat(httpLink),
        },
        buildQuery: videoQueryHandler,
      });
      setDataProvider(() => builtDataProvider);
    };
    setupDataProvider();
  }, []);

  if (!dataProvider) {
    return null;
  }

  return (
    <Admin dataProvider={dataProvider} authProvider={AuthProvider}>
      <Resource
        name="Background"
        options={{ label: 'Backgrounds' }}
        create={BackgroundCreate}
        edit={BackgroundEdit}
        list={BackgroundList}
      />
      <Resource
        name="BackgroundCategory"
        options={{ label: 'Background categories' }}
        create={BackgroundCategoryCreate}
        edit={BackgroundCategoryEdit}
        list={BackgroundCategoryList}
      />
      <Resource name="Category" create={CategoryCreate} edit={CategoryEdit} list={CategoryList} />
      <Resource
        name="Character"
        create={CharacterCreate}
        edit={CharacterEdit}
        list={CharacterList}
        show={CharacterShow}
      />
      <Resource name="File" list={FileList} show={FileShow} />
      <Resource
        name="UserVideo"
        options={{ label: 'Featured videos' }}
        create={UserVideoCreate}
        list={UserVideoList}
        edit={UserVideoEdit}
      />
      <Resource
        name="UGVideo"
        options={{ label: 'User Generated videos' }}
        list={UserGeneratedContentList}
        edit={UserGeneratedContentEdit}
      />
    </Admin>
  );
}

export default App;
