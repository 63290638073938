import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  List,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { ResourcePagination } from './utils';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 23;

export const BackgroundCategoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
      </SimpleForm>
    </Create>
  );
};

export const BackgroundCategoryEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const BackgroundCategoryList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ResourcePagination />}
      sort={{field: 'name', order: 'ASC'}}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};
